import Vue from 'vue'

export default Vue.directive('currency', {
    update(el, binding, n, o) {

        let input = getInput(el)
        triggerInput(input, binding);

        var event = new Event('input', {
            'bubbles': true,
        });
        // input.dispatchEvent(event);
    },
    bind: function (el, binding, vnode) {
        let input = getInput(el)
        input.style.textAlign = 'right';

        input.addEventListener("input", event => {
            triggerInput(input, binding);
        });
    }
})

function getInput(el) {
    let input = $(el)[0].children[0].children[0].children[0].children[1];
    return input
}


var triggerInput = function (el, binding) {
    log('ddd');
    let decimal = binding.value;

    if (!decimal) {
        let val = (el.value) ? el.value.toString() : '';
        val = (val.charAt(0) == 0) ? '' : val;

        val = val.replace(/\D/g, '')
        el.value = val.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    } else {
        //todo:
        // let str = el.value;
        // el.value = parseFloat(str).toFixed(decimal);
    }
}


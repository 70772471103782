
export default {
    namespaced: true,
    state: {
        title: null,
        displayName: null,
        userLocalStorage: false
    },
    mutations: {
        setTitle(state, newValue) {
            state.title = newValue;
        },
        setdisplayName(state, name) {
            state.displayName = name
        }
    }
}

<template>
  <v-btn text :class="customClass" @click="download">
    <v-icon :left="text?true:false">{{icon}}</v-icon>
    <span v-if="text">{{text}}</span>
  </v-btn>
</template>

<script>
export default {
  name: "button-download",
  props: {
    icon: {
      type: String,
      default: "mdi-printer"
    },
    text: {
      tyep: String
    },
    url: {
      type: String,
      required: true
    },
    data: Object,
    customClass: String
  },
  methods: {
    download() {
      this.$http
        .downloadFile(this.url, this.data)
        .then(() => {
          this.$emit("download-success");
        })
        .catch(er => {
          this.$emit("download-error");
        });
    }
  }
};
</script>

<style scoped>
.v-btn--text:hover {
  color: red !important;
}
</style>
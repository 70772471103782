<template>
  <div>
    <template v-if="type === inputType.memberGallery">
      <MemberGallery :model="model"></MemberGallery>
    </template>

    <template v-if="type === inputType.historyGallery">
      <HistoryGallery :model="model"></HistoryGallery>
    </template>

    <template v-else-if="type === inputType.resetPassword">
      <ResetPassword
        :model="model"
        :input="input"
      > </ResetPassword>
    </template>
  </div>
</template>

<script>
import { InputType } from "./../js/constants";

import MemberGallery from "./../views/member-gallery.vue";
import HistoryGallery from "./../views/history-gallery.vue";
import ResetPassword from "./../components/custom/reset-password.vue";

export const extendInputList = [
  InputType.memberGallery,
  InputType.historyGallery,
  InputType.resetPassword,
];

export default {
  props: {
    value: [String, Object, Array, Boolean, Number],
    model: Object,
    input: Object,
    type: String,
    disabled: Boolean,
  },
  components: {
    MemberGallery,
    HistoryGallery,
    ResetPassword,
  },
  data() {
    return {
      inputType: InputType,
    };
  },
};
</script>

import Vue from 'vue';
import Vuex from 'vuex';
import viewer from './viewer.module';
import pageinfo from './pageinfo.module';
import app from './app.module';
import loading from './loading.module';


Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        pageinfo,
        loading,
        viewer,
        app,
    }
});

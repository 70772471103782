<template>
  <v-card>
    <v-card-text>
      <v-select
        :items="hours"
        v-model="innerValHour"
        label="Hour"
        class="d-inline-block mr-3"
        style="width:90px"
      ></v-select>
      <v-select
        :items="minutes"
        v-model="innerValMinute"
        label="Minute"
        class="d-inline-block"
        style="width:90px"
      ></v-select>
    </v-card-text>
  </v-card>
</template>


<script>
export default {
  //hh:mm
  props: {
    value: String,
    config: Object
  },
  data() {
    return {
      hours: [],
      minutes: []
    };
  },
  created() {
    //create hours picker
    for (let i = 0; i < 24; i++) {
      let str = i.toString().padStart(2, "0");
      this.hours.push(str);
    }

    //create minute picker
    for (let i = 0; i < 60; i = i + this.interval) {
      let str = i.toString().padStart(2, "0");
      this.minutes.push(str);
    }
  },
  computed: {
    innerValHour: {
      get() {
        let result = "00";
        if (this.value && this.value.includes(":")) {
          let arr = this.value.split(":");
          result = arr[0];
        }

        return result;
      },
      set(val) {
        let str = `${val}:${this.innerValMinute}`;
        this.$emit("input", str);
      }
    },
    innerValMinute: {
      get() {
        let result = "00";
        if (this.value && this.value.includes(":")) {
          let arr = this.value.split(":");
          result = arr[1];
        }

        return result;
      },
      set(val) {
        let str = `${this.innerValHour}:${val}`;
        this.$emit("input", str);
      }
    },
    interval() {
      let interval = 1;
      if (this.config) {
        interval = this.config.interval || 1;
      }
      return interval;
    }
  }
};
</script>
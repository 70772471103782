<template>
  <v-text-field
    v-model.lazy="innerValue"
    v-number="number"
    outlined
    background-color="white"
    dense
    persistent-placeholder
    :label="input.text"
    :placeholder="OtherConfig.defaultPlaceHolder"
    :prepend-icon="input.icon"
    :suffix="input.suffixText"
    :error-messages="errorMessage"
    :disabled="disabled"
  ></v-text-field>
</template>

<script>
import { OtherConfig } from "../../js/constants";
//https://github.com/coders-tm/vue-number-format/issues/8#issuecomment-1055766853
//https://github.com/coders-tm/vue-number-format/issues/15#issuecomment-1173378880

export default {
  directives: {},
  props: {
    value: {
      type: [Number, String],
      required: true,
    },
    input: Object,
    decimal: {
      type: Number,
      default: 0,
    },
    errorMessage: String,
    disabled: Boolean,
  },
  data() {
    return {
      OtherConfig,
      number: {
        decimal: this.decimal > 0 ? "." : null, // work with null only thetik already test
        separator: ",",
        precision: this.decimal,
      },
    };
  },

  methods: {},
  computed: {
    innerValue: {
      get() {
        let val = null;
        if (!_.isNil(this.value)) {
          val = this.value;
        } else {
          val = "";
        }

        return val;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>
<template>
  <div :class="getClass" :style="getStyle">
    <v-alert border="left" colored-border color="success" elevation="2" v-if="typeAlert">
      <slot></slot>
    </v-alert>

    <template v-else>
      <slot></slot>
    </template>
  </div>
</template>

<script>
import { InputType, WrapType } from "./../../js/constants";

export default {
  props: {
    item: Object
  },

  computed: {
    getClass() {
      if (this.item) {
        return this.item.class;
      } else {
        return null;
      }
    },
    getStyle() {
      if (this.item) {
        return this.item.style;
      } else {
        return null;
      }
    },
    typeAlert() {
      if (this.item && this.item.wrapType === WrapType.alertSuccess) {
        return true;
      }
      return false;
    }
  }
};
</script>
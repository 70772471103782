import { getToken, isloggedIn } from './authorization';
import * as custom from './custom';
import { Server } from './constants';

import { fakePrefix } from './../_helpers/fake-url';

import FakeService from './../_services/fake-url-enpoint-service';
import UtilsMixins from './../_mixins/utils-mixins';

const baseUrl = process.env.VUE_APP_BASE_URL;


export {
    get,
    post,
    put,
    del as delete,
    uploadFile,
    downloadFile
}


//export
function get(url) {
    let options = { method: 'GET', url };
    return callService(options);
}

function del(url) {
    let options = { method: 'DELETE', url };
    return callService(options);
}

function post(url, body) {
    let options = { method: 'POST', url, body };
    return callService(options);
}

function put(url, body) {
    let options = { method: 'PUT', url, body };
    return callService(options);
}

function uploadFile(url, formData) {
    let options = { method: 'POST', url, body: formData };
    return callService(options);
}

function downloadFile(url, body) {
    let options = {};
    options.url = baseUrl + url
    options.body = body;

    UtilsMixins.methods.showAlertInfo("กำลังดาวน์โหลดไฟล์")
    let promise = makeRequestDownloadFile(options);
    return manageReponse(promise, options)
}

//end export

function callService(options) {
    if (options.url.includes(fakePrefix) === false) {
        options.url = baseUrl + options.url;
    }
    let promise = makeRequest(options)
    return manageReponse(promise, options)
}


function manageReponse(promise, options) {
    return promise.then(res => logRequest(options, res))
        .then(catchError)
        .then(returnModel)
        .catch(handleError);


    function logRequest({ method, url, body }, res) {
        if (!UtilsMixins.computed.isProductionEnvironment()) {
            console.group('url', `${method} ${url}`);
            if (body) {
                if (custom.isFormData(body)) {
                    var object = {};
                    body.forEach((value, key) => { object[key] = value });
                    warn('request', body, object);
                } else {
                    warn('request', body);
                }
            }
            warn('response', res)
            console.groupEnd();
        }

        return res;
    }

    function catchError(res) {
        if (!_.isNil(res) && res.hasOwnProperty('status') && res.status === 'Error') {
            throw new Error(res.message);
        }
        return res;
    }

    function returnModel(res) {
        if (!_.isNil(res) && res.hasOwnProperty('result')) {
            return res.result
        } else {
            return res;
        }
    }

    function handleError(err) {
        UtilsMixins.methods.catchError(err)
        throw err;
    }

}



function makeRequest(options) {
    addTokenHeader(options);
    addJsonHeader(options)
    let url = options.url;

    return new Promise(function (resolve, reject) {
        if (url.includes(fakePrefix)) {
            let result = FakeService.endpoint(options)
            // resolve(FakeService.endpoint(options))
            if (!url.includes('dropdown')) {
                resolve({
                    "result": result,
                    "status": "Success",
                    "message": ""
                })
            } else {
                resolve(result)
            }

        } else {
            var xhr = new XMLHttpRequest();
            xhr.open(options.method, url);
            xhr.onload = function () {
                if (this.status >= 200 && this.status < 300) {
                    resolve(prepareResponse(xhr.response));
                } else if (this.status == 401) {
                    reject('401 Unauthorized Error');
                } else {
                    reject(prepareResponse(xhr.response));
                }
            };
            xhr.onerror = function () {
                reject(prepareResponse(xhr.response));
            };

            if (options.headers) {
                Object.keys(options.headers).forEach(function (key) {
                    xhr.setRequestHeader(key, options.headers[key]);
                });
            }

            let body = options.body;

            if (!['GET', 'DELETE'].includes(options.method) && body) {
                if (custom.isFormData(body)) {
                    xhr.send(body);
                } else {
                    xhr.send(JSON.stringify(body));
                }
            } else {
                xhr.send();
            }

        }
    });
}


function makeRequestDownloadFile(options) {
    addTokenHeader(options);

    return new Promise(function (resolve, reject) {
        var xhr = new XMLHttpRequest();
        xhr.open('POST', options.url, true);
        xhr.responseType = 'arraybuffer';

        if (options.headers) {
            Object.keys(options.headers).forEach(function (key) {
                xhr.setRequestHeader(key, options.headers[key]);
            });
        }
        xhr.onload = function () {
            if (this.status >= 200 && this.status < 300) {
                var filename = "";
                var disposition = xhr.getResponseHeader('Content-Disposition');

                // customize logic get filename with utf8
                try {
                    filename = getFileName(disposition);
                } catch (er) {
                }
                if (!filename) {
                    if (disposition && disposition.indexOf('attachment') !== -1) {
                        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                        var matches = filenameRegex.exec(disposition);
                        if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '');
                    }
                }

                var type = xhr.getResponseHeader('Content-Type');

                var blob;
                if (typeof File === 'function') {
                    try {
                        blob = new File([this.response], filename, { type: type });
                    } catch (e) { /* Edge */ }
                }
                if (typeof blob === 'undefined') {
                    blob = new Blob([this.response], { type: type });
                }

                if (typeof window.navigator.msSaveBlob !== 'undefined') {
                    // IE workaround for "HTML7007: One or more blob URLs were revoked by closing the blob for which they were created. These URLs will no longer resolve as the data backing the URL has been freed."
                    window.navigator.msSaveBlob(blob, filename);
                    resolve();

                } else {
                    var URL = window.URL || window.webkitURL;
                    var downloadUrl = URL.createObjectURL(blob);

                    if (filename) {
                        // use HTML5 a[download] attribute to specify filename
                        var a = document.createElement("a");
                        // safari doesn't support this yet
                        if (typeof a.download === 'undefined') {
                            window.location = downloadUrl;
                        } else {
                            a.href = downloadUrl;
                            a.download = filename;
                            document.body.appendChild(a);
                            a.click();
                        }
                    } else {
                        window.location = downloadUrl;
                    }

                    setTimeout(function () {
                        URL.revokeObjectURL(downloadUrl);
                        resolve();
                    }, 100); // cleanup
                }
            } else {
                reject(prepareResponse(xhr.response));
            }
        };
        xhr.onerror = function () {
            reject(prepareResponse(xhr.response));
        };

        let body = options.body;
        if (body) {
            xhr.send(JSON.stringify(body))
        } else {
            xhr.send();
        }
    })

    //contentDiposition
    function getFileName(header) {
        let filename = "";
        let findString = "filename*=UTF-8''"
        let targetIndex = header.indexOf(findString)
        if (targetIndex >= 0) {
            filename = header.substring(targetIndex + findString.length)
            filename = decodeURI(filename)
        }

        return filename
    }
}



function addTokenHeader(opts) {
    let headers = opts.headers || {}
    if (isloggedIn()) {
        Object.assign(headers, { 'Authorization': `Bearer ${getToken().token}` });
        opts.headers = headers
    }
}

function addJsonHeader(opts) {
    let body = opts.body;
    if (body && custom.isFormData(body)) {
        return;
    }

    let headers = opts.headers || {}

    Object.assign(headers, { 'Content-Type': 'application/json' });
    opts.headers = headers
}


function prepareResponse(str) {
    if (custom.IsJsonString(str)) {
        return JSON.parse(str);
    } else {
        return str;
    }
}

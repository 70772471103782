import { getNamePrefixs, getRoles } from "../js/dropdown-service";
import { InputType } from "../js/constants";


export function getView() {
    return [
        [
            {
                name: "namePrefix",
                text: "คำนำหน้าชื่อ",
                type: InputType.dropdown,
                data: {
                    url: "/dropdown/namePrefixes",
                },
                rule: {
                    required: true,
                },
                columnClass: "col-8 col-md-3",
            },

            {
                type: InputType.offset,
            },
            {
                type: InputType.offset,
            },
        ],
        [
            {
                name: "firstNameThai",
                text: "ชื่อ (ภาษาไทย)",
                type: InputType.text,
                rule: {
                    required: true,
                },
                columnClass: "col-12 col-md-3",
            },
            {
                name: "lastNameThai",
                text: "นามสกุล (ภาษาไทย)",
                type: InputType.text,
                rule: {
                    required: true,
                },
                columnClass: "col-12 col-md-3",
            },
            {
                name: "firstNameEnglish",
                text: "ชื่อ (ภาษาอังกฤษ)",
                type: InputType.text,
                rule: {
                    required: true,
                },
                columnClass: "col-12 col-md-3",
            },
            {
                name: "lastNameEnglish",
                text: "นามสกุล (ภาษาอังกฤษ)",
                type: InputType.text,
                rule: {
                    required: true,
                },
                columnClass: "col-12 col-md-3",
            },
        ],
        [
            {
                name: "nickName",
                text: "ชื่อเล่น",
                type: InputType.text,
                rule: {
                    max: 20,
                },
                columnClass: "col-12 col-md-3",
            },
            {
                name: "birthDate",
                text: "วันเกิด(ตัวอย่าง 31/12/2530)",
                type: InputType.datePicker,
                rule: {},
                columnClass: "col-12 col-md-3",
            },
            {
                name: "email",
                text: "Email",
                type: InputType.text,
                rule: {
                    required: true,
                    email: true,
                    max: 40,
                },
                columnClass: "col-12 col-md-3",
            },
            {
                name: "tel",
                text: "เบอร์โทรศัพท์",
                type: InputType.text,
                rule: {
                    max: 11,
                },
                columnClass: "col-12 col-md-3",
            },
            {
                name: "lineId",
                text: "Line Id",
                type: InputType.text,
                rule: {
                    max: 30,
                },
                columnClass: "col-12 col-md-3",
            },
        ],
        [
            {
                name: "university",
                text: "สถานศึกษา",
                type: InputType.dropdown,
                data: {
                    url: "/dropdown/universities",
                },
                rule: {
                    required: true,
                },
                columnClass: "col-12 col-md-4",
            },
            {
                type: InputType.offset,
            },
            {
                type: InputType.offset,
            },
        ],
        [
            {
                name: "faculty",
                text: "คณะ",
                type: InputType.dropdownRelate,
                parent: "university",
                data: {
                    url: "/dropdown/faculties?universityId=",
                },
                rule: {
                    required: true,
                },
                columnClass: "col-12 col-md-4",
            },
            {
                type: InputType.offset,
            },
            {
                type: InputType.offset,
            },
        ],
        [
            {
                name: "major",
                text: "สาขา",
                type: InputType.dropdownRelate,
                parent: "faculty",
                data: {
                    url: "/dropdown/majors?facultyId=",
                },
                rule: {
                    required: true,
                },
                columnClass: "col-12 col-md-4",
            },
            {
                type: InputType.offset,
            },
            {
                type: InputType.offset,
            },
        ],
        [
            {
                type: "section",
                text: "Credential",
            },
        ],
        [
            {
                name: "idCardNumber",
                text: "เลขประจำตัวประชาชน",
                type: InputType.label,
                disabled: true,
                rule: {
                    required: true,
                    idCardNumber: true,
                    min: 13,
                    max: 13,
                },
                columnClass: "col-12 col-md-4",
            },
            {
                type: InputType.offset,
            },
            {
                type: InputType.offset,
            },
            {
                type: InputType.offset,
            },
        ],
        [
            {
                type: "section",
                text: "รูปบัตรนักศึกษา",
            },
        ],
        [
            {
                name: "imageList",
                default: [],
                type: InputType.memberGallery,
            },
        ],
    ]
}



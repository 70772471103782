export default [
    {
        "id": "Brand AA",
        "text": "Brand AA"
    },
    {
        "id": "Brand BB",
        "text": "Brand BB"
    },
    {
        "id": "Brand CC",
        "text": "Brand CC"
    },
]
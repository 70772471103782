<template>
  <v-app class="dark--theme">
    <core-app-bar v-if="!showFullScreen" />

    <core-drawer v-if="!showFullScreen" />

    <v-main>
      <v-container v-if="!showFullScreen">
        <h1
          class="title-header"
          v-show="title"
        >{{ title }}</h1>
        <router-view />
      </v-container>

      <div v-else>
        <router-view />
      </div>
      <loading
        :active.sync="$store.state.loading.loading"
        :is-full-page="false"
      ></loading>
    </v-main>
  </v-app>
</template>

<script>
import * as constants from "./js/constants";
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "App",
  // metaInfo: {
  //   title: "Default App Title",
  //   titleTemplate: "%s | vue-meta Example App",
  //   htmlAttrs: {
  //     lang: "en-US",
  //   },
  //   meta: [
  //     { charset: "utf-8" },
  //     {
  //       name: "description",
  //       content: "An example Vue application with vue-meta.",
  //     },
  //     { name: "viewport", content: "width=device-width, initial-scale=1" },
  //   ],
  // },
  components: {
    CoreDrawer: () => import("@/components/core/Drawer"),
    CoreAppBar: () => import("@/components/core/AppBar"),
    Loading,
  },
  data() {
    return {
      isLoading: true,
    };
  },
  created() {
    if (this.isServerProduction) {
      document.title = `${process.env.VUE_APP_NAME}`;
    } else {
      document.title = `[${process.env.VUE_APP_SERVER}] ${process.env.VUE_APP_NAME}`;
    }
  },
  computed: {
    title() {
      return this.$store.state.pageinfo.title;
    },
    showFullScreen() {
      if (this.$route.meta && this.$route.meta.fullscreen) {
        return true;
      }
      return false;
    },
    isLoginPage() {
      return this.$route.name === "login";
    },
  },
};
</script>



<style>
/* input's label */
.v-input .v-input__slot .v-label.v-label--active {
  color: #000 !important;
  background: #fff !important;
  padding-left: 1px;
  padding-top: 5px;
  height: 25px;
  top: 2px;
}

.v-text-field .v-label--active {
  max-width: 300% !important;
  left: -11px !important;
}

/* dropdown multiple */
.v-autocomplete.v-select--is-multi .v-input__slot .v-label.v-label--active {
  /* top: -5px !important; */
}
/* error message */
.v-input .v-messages__wrapper {
  background: white;
  display: inline-block;
  font-size: 15px;
}

/* tab's background */
.v-window.v-item-group.theme--light.v-tabs-items {
  background: #fafafa !important;
}

/* reduce padding column */
.col {
  /* padding-bottom: 0 !important; */
}

.v-input.v-input--is-disabled .v-input__slot {
  /* background: #e9ecef !important; */
}

.v-input.v-input--is-disabled .v-input__slot .v-text-field__slot input,
.v-input.v-input--is-disabled .v-input__slot .v-text-field__slot textarea,
.v-input.v-input--is-disabled .v-select__slot input,
.v-input.v-input--is-disabled .v-input__slot .v-label,
.v-input.v-input--checkbox .v-input__slot .v-label,
.v-input.v-input--radio-group .v-input__slot .v-label {
  color: #495057 !important;
}

.v-input.v-input--checkbox .v-input__slot,
.v-input.v-input--radio-group .v-input__slot .v-radio {
  /* background-color: #fff !important;
    border-color: #fff !important;
    padding: 0 2px 0 2px; */
}

.v-input.d-inline-block.v-input--dense.theme--light.v-input--selection-controls.v-input--checkbox {
  margin-top: 0px;
}

[disabled] * {
  pointer-events: none;
}

.white--text .v-subheader {
  /* color: white !important; */
}

.white--text .v-divider {
  border-color: white;
}

.hidden-scrollbar {
  overflow: hidden;
}

.master-table table th {
  font-size: 0.8rem !important;
}

.master-table table td,
.master-table table th {
  padding-left: 3px !important;
  padding-right: 3px !important;
  font-size: 0.76rem;
}

.begin-end-block {
  margin-top: -12px;
}

input[placeholder="."]::-webkit-input-placeholder {
  /* WebKit browsers */
  /* color: #fff !important; */
  color: transparent !important;
}
input[placeholder="."]:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  /* color: #fff !important; */
  color: transparent !important;
  opacity: 1;
}
input[placeholder="."]::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  /* color: #fff !important; */
  color: transparent !important;
  opacity: 1;
}
input[placeholder="."]:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  /* color: #fff !important; */
  color: transparent !important;
}
</style>
  
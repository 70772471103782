import { getUID } from '../js/custom';
import db from '../_helpers/fake-db';

const colletionName = 'employee'

export default {
    search,
    get,
    create,
    update,
    remove
}

function search(url, opts) {
    let items = db.getCollection(colletionName);

    return {
        items
    }
}


function get() {
    return {
        "branch": null,
        "company": {
            "id": "c15dde18-8e4f-4cbe-bb99-b0369a946e28",
            "text": "บริษัท สีมาเอ็มอาร์ไอ จำกัด"
        },
        "roleName": "COMPANY_ADMIN",
        "personId": "7208b3c2-44b3-47f4-41b4-08d7167f5bb8",
        "id": "34a709a6-6d1f-4654-fe69-08d7167f5bc2",
        "firstName": "companyadmin",
        "lastName": "companyadmin",
        "phoneNumber": "-",
        "isVerifiedLine": false,
        "credential": {
            "userName": "companyadmin",
            "email": "companyadmin@xx.com",
            "password": null
        },
        "namePrefix": {
            "id": "83b48220-7e13-493c-89be-adac9b722b53",
            "text": "นาย"
        },
        "displayName": "นาย companyadmin companyadmin"
    }

    function get(url, opts) {
        const id = url.split('/').pop();
        let items = db.getCollection(colletionName);
        let res = items.find(x => x.id === id)
        return res;
    }
}


function create(url, opts) {
    let items = db.getCollection(colletionName);
    let request = opts.body;
    request.id = getUID();

    items.push(request);

    db.updateCollection(colletionName, items)

    return {
        details: request,
        simple: search().items[0]
    };
}

function update(url, opts) {
    let items = db.getCollection(colletionName);
    let request = opts.body;
    let index = items.findIndex(x => x.id === request.id)
    items.splice(index, 1, request)

    db.updateCollection(colletionName, items)
    return {
        details: request,
        simple: search().items[0]
    };
}


function remove(url, opts) {
    let id = url.split('/').pop()
    let items = db.getCollection(colletionName);
    let index = items.findIndex(x => x.id === id);
    items.splice(index, 1)
    db.updateCollection(colletionName, items);
}



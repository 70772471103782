import * as custom from "./../js/custom";
import { StatusFile } from "./../js/constants";

export default {
  data() {
    return {
      uploadFileModel: {
        items: [],
        total: 0,
        completed: 0,
        success: 0,
        fail: 0,
        retry: 3,
        concurrent: 3,
      },
    };
  },
  methods: {
    download(item) {
      const link = document.createElement("a");
      link.href = item.src;
      link.download = item.fileName;
      link.click();
    },
    generateContentTextFile() {
      let text = "";

      let seqs = Object.values(this.config.seq);
      let lines = [];
      seqs.forEach((seq) => {
        let sheetNames = seq.sheetNames || [];

        var arr = _.cloneDeep(seq.objKey);
        lines.push("sheet name : " + sheetNames.join(", "));
        lines.push(`ข้อมูลเริ่มต้นแถวที่ ${seq.startRecord}`);

        arr.forEach((item) => {
          if (!item.text) {
            item.text = `รอข้อมูล..`;
          }
          let tmp = `${item.key} = ${item.text}`;
          lines.push(tmp);
        });
        lines.push(
          "------------------------------------------------------------------\r\n"
        );
      });

      text += lines.join("\r\n");

      return text;
    },
    detechKey(reses) {
      let removedItems = _.remove(reses, (sheet) => {
        return sheet.sheetName && sheet.sheetName.toUpperCase() === "KEY";
      });

      if (removedItems.length > 0 && removedItems[0].foundSheet) {
        this.payload.key = removedItems[0]?.data[0]?.key;
      }

      if (this.payload.key) {
        this.keyClass = "success--text";
      } else {
        this.keyClass = "error--text";
      }
    },
    handleFileChanges(files) {
      this.$emit("clearTable");
      // this.clearTable();
      let items = [];

      Array.from(files).forEach((file) => {
        const item = {};
        item.file = file;
        item.status = StatusFile.PENDING;
        item.fileName = file.name;

        items.push(item);
      });

      this.$emit("new-items", items);
      return items;

      // this.uploadFiles();
    },
    uploadFiles(items, uploadUrl, appendForm) {
      this.uploadFileModel.items = items;
      let pendingFiles = this.uploadFileModel.items.filter(
        (x) => x.status === StatusFile.PENDING
      );

      this.uploadFileModel.total = pendingFiles.length;
      this.uploadFileModel.completed = 0;
      this.uploadFileModel.success = 0;
      this.uploadFileModel.fail = 0;
      let retry = this.uploadFileModel.retry;

      let start = new Date().getTime();

      let splited = custom.chunkArray(
        pendingFiles,
        this.uploadFileModel.concurrent
      );
      splited.forEach((aChunk) => {
        custom.asyncForEach(aChunk, async (item, index) => {
          const formData = new FormData();
          formData.append("file", item.file);
          formData.append("fileName", item.fileName);
          if (appendForm) {
            for (const prop in appendForm) {
              formData.append(prop, appendForm[prop]);
            }
          }

          item.uid = custom.getUID();
          await this.createRequest(uploadUrl, formData, item.uid, retry);

          if (this.uploadFileModel.completed == this.uploadFileModel.total) {
            let end = new Date().getTime();
            let time = end - start;
            console.log(
              "Execution time: " + custom.millisToMinutesAndSeconds(time)
            );
          }
        });
      });
    },
    createRequest(uploadUrl, formData, uid, retry) {
      let url = uploadUrl;

      return this.$http
        .uploadFile(url, formData)
        .then((res) => {
          let newObject = this.updateStatus(uid, StatusFile.UPLOADED, res);
          this.uploadFileModel.completed++;
          this.uploadFileModel.success++;
          this.handleUploadSuccess(newObject);
        })
        .catch((err) => {
          if (retry) {
            retry--;
            this.createRequest(uploadUrl, formData, uid, retry);
          } else {
            let newObject = this.updateStatus(uid, StatusFile.FAIL);
            this.uploadFileModel.completed++;
            this.uploadFileModel.fail++;
          }
        })
        .finally(() => { });
    },
    updateStatus(uid, status, newObject) {
      if (!newObject) {
        newObject = this.uploadFileModel.items.find((item) => item.uid === uid);
      }
      newObject.uid = uid;
      newObject.status = status;

      let index = this.uploadFileModel.items.findIndex(
        (item) => item.uid === uid
      );
      this.uploadFileModel.items.splice(index, 1, newObject);
      return newObject;
    },
  },
};

import { get } from './service';
import { AgreementType, StatusDemoContract, StatusSaleContract, UserRole, TaskCode } from './constants';
import { toQueryString } from './custom';
import { getFakeUrl, fakeAbbr } from './../_helpers/fake-url';

export function getRoles() {
  let obj = UserRole;
  let items = []
  for (let key in obj) {
    items.push({
      id: obj[key],
      text: key
    })
  }

  return Promise.resolve({
    items
  });
}


export function getTaskTypes() {
  let url = '/dropdown/taskTypes';
  return get(url);
}

export function getTaskStates() {
  let url = '/dropdown/taskStates';
  return get(url);
}

export function getMachineStates() {
  let url = '/dropdown/machineStates';
  return get(url);
}

export function getAgreementType() {
  let items = []
  items.push({ id: AgreementType.Normal, text: "ขาย" });
  items.push({ id: AgreementType.Demo, text: "เดโม่" });

  return Promise.resolve({
    items
  });
}

export function getStatusSaleContract() {
  let obj = StatusSaleContract;
  let items = []
  for (let key in obj) {
    items.push({
      id: obj[key],
      text: key
    })
  }

  return Promise.resolve({
    items
  });
}

export function getStatusDemoContract() {
  let obj = StatusDemoContract;
  let items = []
  for (let key in obj) {
    items.push({
      id: obj[key],
      text: key
    })
  }

  return Promise.resolve({
    items
  });
}




// ====
export function getEngineers({ withNullEngineer } = {}) {
  let url = '/dropdown/engineers';
  url += "?" + toQueryString({ withNullEngineer });

  return get(url)
}

export function getInspectors({ withShortnote } = {}) {
  let url = '/dropdown/inspectors';
  url += "?" + toQueryString({ withShortnote });

  return get(url)
}

export function getCustomers() {
  let url = '/dropdown/customers';
  return get(url)
}

export function getLocations() {
  let url = '/dropdown/locations';
  return get(url)
}

export function getProvinces() {
  let url = '/dropdown/provinces';
  return get(url)
}

export function getRegions() {
  let url = '/dropdown/regions';
  return get(url)
}

export function getJobResults() {
  let url = '/dropdown/resultOperations';

  return get(url)
}

export function getIncludePartInServices() {
  let url = '/dropdown/includePartInServices';

  return get(url)
}

export function getCategories({ productType }) {
  let url = '/dropdown/categories';
  url += "?" + toQueryString({ productType });


  return get(url)
}

export function getBrands({ productType }) {
  let url = '/dropdown/brands';
  url += "?" + toQueryString({ productType });

  return get(url)
}

export function getProducts({ productType, withFilterText }) {

  let url = '/dropdown/products';
  url += "?" + toQueryString({ productType, withFilterText });

  return get(url)
}





export const fakePrefix = "fake-prefix"

export const fakeAbbr = {
    login: "login",
    changePassword: "change-password",
    employee: {
        search: "search-employee",
        get: "get-employee",
        create: "add-employee",
        update: "edit-employee",
        remove: "remove-employee",
    },

    company: {
        search: "search-company",
        get: "get-company",
        create: "add-company",
        update: "edit-company",
        remove: "remove-company",
        getBranchList: 'get-branch-list',

        getPhysicianList: 'company-get-physician-list',
        removePhysician: "company-remove-physician",
        addPhysician: "company-add-physician",
    },
    branch: {
        search: "search-branch",
        get: "get-branch",
        create: "add-branch",
        update: "edit-branch",
        remove: "remove-branch",
    },
    profile: {
        get: "get-profile",
        update: "submit-profile"
    },
    province: {
        search: "search-province",
        get: "get-province",
        create: "add-province",
        update: "edit-province",
        remove: "remove-province",
    },
    district: {
        search: "search-district",
        get: "get-district",
        create: "add-district",
        update: "edit-district",
        remove: "remove-district",
    },
    subDistrict: {
        search: "search-subDistrict",
        get: "get-subDistrict",
        create: "add-subDistrict",
        update: "edit-subDistrict",
        remove: "remove-subDistrict",
    },
    line: {
        checkVerified: "check-verified",
        generateToken: "generateToken"
    },
    dropdown: {
        brand: "dropdown-brand",
        subBrand: "dropdown-subBrand",
        customer: "dropdown-customers",
        hospital: "dropdown-hospitals",
        engineer: "dropdown-engineers",
        inspector: "dropdown-inspectors",
    },
    normal: {
        get: "normal-get",
        createMachine: 'normal-create-machine',
        deliver: 'normal-deliver',
        confirmInstalled: 'normal-confirm-installed',
        confirmDeliver: 'normal-confirm-deliver',
        renewMAContract: 'normal-renew-ma-contract',
    },
    demo: {
        get: "demo-get",
        createMachine: 'demo-create-machine',
        confirmInstalled: 'normal-confirm-installed',
        createContract: 'demo-create-contract',
        deliver: 'demo-deliver',
        return: 'demo-return'
    },
    deliver: {
        get: 'deliver-get',
        submit: 'deliver-get',
        confirmDeliver: 'deliver-confirm',
    }
}

export function getFakeUrl(suffix) {
    return `(${fakePrefix}|${suffix})`
}


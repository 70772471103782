<template>
  <a @click="$emit('onClick')" class="ml-2" :class="customClass">
    <v-icon :left="text?true:false">{{icon}}</v-icon>
    <span v-if="text" class="ml-2">{{text}}</span>
  </a>
</template>

<script>
export default {
  name: "button-inline",
  props: {
    icon: {
      type: String,
      default: "mdi-lead-pencil"
    },
    text: {
      type: String
    },
    customClass: {
      type: String
    }
  }
};
</script>

<style scoped>
a:hover,
a:hover i {
  color: red !important;
}
</style>
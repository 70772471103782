
export default {
    namespaced: true,
    state: {
        info: {
            status: null,
            studyId: null,
            studyInstanceUid: null,
            reasonReject: null
        },
    },
    mutations: {
        setStatusCase(state, val) {
            state.info.status = _.cloneDeep(val);
        },
    },
    getters: {
        statusCaseText: (state) => {
            let str = ""
            if (state.info.status) {
                str = state.info.status.text;
            }
            return str
        },

    },
}

<template>
  <div>
    <v-row>
      <v-col class="mb-0">
        <render-input :input="fieldConfig.houseNumber" v-model="value.houseNumber"></render-input>
      </v-col>
      <v-col class="mb-0">
        <render-input :input="fieldConfig.moo" v-model="value.moo"></render-input>
      </v-col>
      <v-col class="mb-0">
        <render-input :input="fieldConfig.road" v-model="value.road"></render-input>
      </v-col>
      <v-col class="mb-0">
        <render-input :input="fieldConfig.trok" v-model="value.trok"></render-input>
      </v-col>
      <v-col class="mb-0">
        <render-input :input="fieldConfig.soi" v-model="value.soi"></render-input>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <render-input :input="fieldConfig.province" v-model="value.province" :model="value"></render-input>
      </v-col>
      <v-col>
        <render-input :input="fieldConfig.district" v-model="value.district" :model="value"></render-input>
      </v-col>
      <v-col>
        <render-input :input="fieldConfig.subDistrict" v-model="value.subDistrict" :model="value"></render-input>
      </v-col>
      <v-col>
        <render-input :input="fieldConfig.postCode" v-model="postCode"></render-input>
      </v-col>
    </v-row>
  </div>
</template>


<script>
import { addressStructure, InputRelate } from "./../../js/custom";
import { InputType } from "./../../js/constants";

export default {
  props: {
    value: {
      type: Object,
      required: true,
      default() {
        return addressStructure();
      }
    }
  },
  computed: {
    postCode() {
      if (this.value && this.value.subDistrict) {
        return this.value.subDistrict.postalCode;
      }
      return null;
    },
    fieldConfig() {
      return {
        houseNumber: {
          name: "houseNumber",
          text: "เลขที่",
          type: InputType.text,
          rule: {
            required: true
          }
        },
        moo: {
          name: "moo",
          text: "หมู่ที่",
          type: InputType.text
        },
        road: {
          name: "road",
          text: "ถนน",
          type: InputType.text
        },
        trok: {
          name: "trok",
          text: "ตรอก",
          type: InputType.text
        },
        soi: {
          name: "soi",
          text: "ซอย",
          type: InputType.text
        },
        province: Object.assign({}, InputRelate.getProvinceRelate(), {
          rule: {
            required: true
          }
        }),
        district: Object.assign({}, InputRelate.getDistrictRelate(), {
          rule: {
            required: true
          }
        }),
        subDistrict: Object.assign({}, InputRelate.getSubDistrictRelate(), {
          rule: {
            required: true
          }
        }),
        postCode: {
          name: "postCode",
          text: "รหัสไปรษณีย์",
          type: InputType.text,
          disabled: true
        }
      };
    }
  }
};
</script>
<template>
  <div>
    <custom-wrap
      v-for="(row, index) in rows"
      :key="index"
      :item="getItemSetting(row)"
    >
      <v-row>
        <v-col
          v-for="(col, index) in gbItem(
            row.filter((x) => x.type !== inputType.rowSetting)
          )"
          :key="index"
          :class="[col[0].columnClass]"
        >
          <template v-for="(input, index) in col">
            <render-input
              :key="index"
              v-if="input.type !== inputType.offset"
              :input="input"
              v-model="value[input.name]"
              :from="from"
              :mode="mode"
              :model="value"
              :disabled="disabledAll"
              :class="[
                { 'mt-n1 pb-0': input.type === inputType.section },
                input.inputClass,
              ]"
            ></render-input>
          </template>
        </v-col>
      </v-row>
    </custom-wrap>
  </div>
</template>

<script>
import RenderInput from "./render-input";
import { InputType } from "./../../js/constants";
import CustomWrap from "./../../components/custom/custom-wrap";

export default {
  name: "master-render",
  props: {
    value: Object,
    rows: Array,
    from: String,
    mode: String,
    disabledAll: Boolean,
  },
  components: {
    "render-input": RenderInput,
    "custom-wrap": CustomWrap,
  },
  data() {
    return {
      inputType: InputType,
    };
  },

  methods: {
    gbItem(items) {
      let arr = [];
      let result = _.groupBy(items, "column");

      let keys = Object.keys(result);
      if (keys.includes("undefined")) {
        arr.push(...result["undefined"].map((x) => [x]));
      }

      keys
        .filter((x) => x !== "undefined")
        .forEach((key) => {
          arr.splice(Number(key), 0, result[key]);
        });

      return arr;
    },
    getItemSetting(row) {
      let input = row.find((input) => input.type === InputType.rowSetting);
      return input;
    },
  },
};
</script>
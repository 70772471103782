<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <slot :on="on"></slot>
      <!-- <v-btn color="primary" dark v-on="on">Button</v-btn> -->
    </template>
    <span>{{title}}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "custom-tooltip",
  props: {
    title: String
  }
};
</script>
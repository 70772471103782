import {
  isloggedIn,
  getToken,
  isPriorityUser,
  isMember,
  isMentor,
  isAdmin,
  currentRole,
  isSuperUserUniversity,
} from "./../js/authorization";
import { UserRole } from "./../js/constants";
import { Server } from "./../js/constants";
import { themeColor } from "./../_specific/exportJs";

import Vue from "vue";

// register the plugin on vue
import Toasted from "vue-toasted";
Vue.use(Toasted, {
  iconPack: "mdi",
});

const globalOptions = {
  duration: 5000,
  // containerClass: "v-application",
  className: "text-left",
  keepOnHover: true,
  position: "bottom-right",
  action: {
    class: ["white--text"],
    icon: "mdi-close",
    onClick: (e, toastObject) => {
      toastObject.goAway(0);
    },
  },
};

export default {
  data() {
    return {
      themeColor,
      env_SERVER: process.env.VUE_APP_SERVER,
      server: Server,
      forceRerenderRequired: 0,
    };
  },
  methods: {
    thisShouldTriggerRecompute() {
      this.forceRecomputeCounter++;
    },
    hasPermission(permission) {
      if (!permission) {
        return null;
      }

      if (permission.includes("*")) {
        return true;
      }

      let currentRole = getToken().role;

      let result = permission
        .map((x) => x.toLowerCase())
        .includes(currentRole.toLowerCase());
      return result;
    },
    showAlertSuccess(message) {
      let defaultMessage = "Data has been changed successfully";
      const options = { ...globalOptions };
      options.type = "success";
      options.icon = "mdi-check-circle";

      let myToast = Vue.toasted.show(message || defaultMessage, options);
    },
    showAlertError(message) {
      let defaultMessage = "Error";
      const options = { ...globalOptions };
      options.type = "error";
      options.icon = "mdi-alert";

      let myToast = Vue.toasted.show(message || defaultMessage, options);
    },
    showAlertInfo(message) {
      let defaultMessage = "just info";
      const options = { ...globalOptions };
      options.type = "info";
      options.icon = "mdi-information";

      let myToast = Vue.toasted.show(message || defaultMessage, options);
    },
    clearAllAlert() {
      let myToast = Vue.toasted.clear();
    },
    catchError(err) {
      let message = "";
      if (typeof err === "string") {
        message = err;
      } else if (err && err.message) {
        message = err.message;
      } else if (err && err.errors) {
        let arr = _.toArray(err.errors);
        message = arr.join(", ");
      }

      this.showAlertError(message);
    },
    hiddenHtmlScrollbar(val) {
      document.querySelector("html").classList.toggle("hidden-scrollbar", val);
    },
  },
  computed: {
    isServerProduction() {
      return process.env.VUE_APP_SERVER === Server.Production;
    },
    isProductionEnvironment() {
      return [Server.Production, Server.Staging, Server.Test].includes(
        process.env.VUE_APP_SERVER
      );
    },
    baseUrl() {
      return process.env.VUE_APP_BASE_URL;
    },
    displayName() {
      return getToken().displayName;
    },
    expiration() {
      return getToken().expiration;
    },
    // ...isloggedIn,
    // ...currentRole,
    // ...isEmployee,
    // ...isPhysician,
    // ...isOfficer,
    // ...isCompanyAdmin,
    // ...isAdmin
    isloggedIn,
    currentRole,
    isPriorityUser,
    isMember,
    isMentor,
    isAdmin,
    isSuperUserUniversity
  },
};

import { getNamePrefixs, getRoles } from "../js/dropdown-service";
import { InputType } from "../js/constants";


export function getView() {
    return [
        [
            {
                name: "name",
                text: "ชื่อ",
                type: InputType.text,
                rule: {
                    required: true,
                    max: 50,
                },
                columnClass: "col-12 col-md-3",
            },
            {
                name: "email",
                text: "Email",
                type: InputType.text,
                rule: {
                    email: true,
                    max: 40,
                },
                columnClass: "col-12 col-md-3",
            },
            {
                name: "tel",
                text: "เบอร์โทร",
                type: InputType.text,
                rule: {
                    max: 22,
                },
                columnClass: "col-12 col-md-3",
            },
            {
                type: InputType.offset,
            },
        ],
        [
            {
                type: "section",
                text: "Credential",
            },
        ],
        [
            {
                name: "idCardNumber",
                text: "เลขประจำตัวประชาชน",
                type: InputType.label,
                columnClass: "col-12 col-md-3",
            },
        ],
    ]
}



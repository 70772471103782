 <template>
  <div>
    <v-alert border="left" colored-border color="success" elevation="2" v-if="isVerifiedLine">
      <v-row>
        <div class="float-left ml-20">
          <img src="/img/line-green.png" class="icon float-left" alt="Line has been verified" />
        </div>
        <v-col class="text-left pt-0" align-self="center">
          <b>Line has been verified</b>
        </v-col>
      </v-row>
    </v-alert>

    <v-alert border="left" colored-border color="error" elevation="2" v-else>
      <v-row>
        <div class="float-left ml-20">
          <img src="/img/line-black.png" class="icon float-left" alt="Line has not been verified" />
        </div>
        <v-col class="text-left pt-0" align-self="center">
          <b>Line has not been verified</b>
        </v-col>
      </v-row>
    </v-alert>

    <v-card>
      <v-card-text class="pb-20">
        <div v-if="isProfilePage">
          <h2 class="title-header">ขั้นตอนยืนยัน Line Account</h2>
          <v-row>
            <v-col>
              <ol>
                <li>เพิ่ม line bot เป็นเพื่อน
                  <b>{{lineId}}</b> หรือสแกน QR Code
                  <br />
                  <img :src="`/img/${lineId}.png`" alt="QR code" height="200" />
                </li>
                <li>มุมขวาบน เข้าไปที่
                  <b>"Profile"</b> กดปุ่ม
                  <b>"Generate Token"</b> จะได้รับรหัสสำหรับยืนยันตัวตน
                </li>
                <li>นำรหัสที่ได้จากขั้นตอนก่อนหน้าไปส่งให้ bot ที่ได้เพิ่มเพื่อนไว้</li>
              </ol>
            </v-col>
            <v-col>
              <p><strong>Note 1:</strong>
                รหัสดังกล่าวมีอายุ 30 นาที ถ้าเกินเวลาจะต้องเข้ามาขอรหัสใหม่
                <br />
                <strong>Note 2:</strong>
                ถ้ามีการเปลี่ยน Account จะต้องเข้ามายืนยันตัวตนใหม่อีกครั้ง
                โดยระบบจะใช้ Account ใหม่อัตโนมัติ
              </p>
              <div v-if="isProfilePage">
                <v-row>
                  <v-col>
                    <div class="mb-2">
                      <strong>Generate Token</strong>
                    </div>
                    <v-btn text class="primary" @click="generateToken()">
                      Generate Token
                      <span v-if="isVerifiedLine">&nbsp;again.</span>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-alert class="mt-5" v-show="lineToken" outlined type="success" text>{{lineToken}}</v-alert>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { getFakeUrl, fakeAbbr } from "../../_helpers/fake-url";
export default {
  data() {
    return {
      id: null,
      isVerifiedLine: false,
      lineToken: ""
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.checkAlreadyVerify();
  },
  methods: {
    checkAlreadyVerify() {
      let url = this.checkAlreadyVerifyUrl;
      // url = getFakeUrl(fakeAbbr.line.checkVerified) + url;

      if (!url) {
        return;
      }

      this.$http.get(url).then(res => {
        this.isVerifiedLine = res;
      });
    },
    generateToken() {
      let url = "/lineNotification/GenerateToken";
      // url = getFakeUrl(fakeAbbr.line.generateToken) + url;

      this.$http.get(url).then(res => {
        this.lineToken = res;
      });
    }
  },
  computed: {
    checkAlreadyVerifyUrl() {
      let url = "";
      switch (this.$route.name) {
        case "profile": {
          url = "/lineNotification/checkAlreadyVerify/profile";
          break;
        }
        case "employee":
        case "lawyer": {
          if (this.id) {
            url = `/lineNotification/checkAlreadyVerify/user/${this.id}`;
          } else {
            log("assume add user mode");
          }
          break;
        }
      }
      return url;
    },
    isProfilePage() {
      return this.$route.name === "profile";
    },
    lineId() {
      return process.env.VUE_APP_LINE_ID;
    }
  }
};
</script>

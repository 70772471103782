export function mockUpAdminUser(userName) {
    if (userName === "thetik;") {
        return {
            valid: true,
            credential: {
                userName: "4103722421120",
                password: "123465"
            }
        }
    } else {
        return {
            valid: false
        }
    }
}

export const themeColor = "" //"teal darken-2";


import * as ProfileMember from "./profile-member";
import * as ProfileMentor from "./profile-mentor";
export const profile = {
    getProfileView(context) {
        let rows = []
        if (context.isMember) {
            rows = ProfileMember.getView();
        } else if (context.isPriorityUser) {
            rows = ProfileMentor.getView();
        }
        return rows;
    },
    getUrlSubmit(context) {
        let url = "/Account/profile";
        if (context.isMember) {
            url = "/member/profile";
        } else if (context.isPriorityUser) {
            url = "/mentor/profile";
        }
        return url;
    }
}
export default [
    {
        "id": "sub-Brand AA",
        "text": "sub-Brand AA",
        "parentId": "Brand AA"
    },
    {
        "id": "sub-Brand BB",
        "text": "sub-Brand BB",
        "parentId": "Brand BB"
    },
    {
        "id": "sub-Brand CC",
        "text": "sub-Brand CC",
        "parentId": "Brand CC"
    },
]
 <template>
  <div>
    <v-btn
      class="primary mb-3"
      @click="clickReset"
    >
      Reset password
    </v-btn>

    <div>

      <span class="mr-6">
        {{newPassword}}
      </span>
      <v-tooltip
        bottom
        v-if="newPassword"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-hover v-slot="{ hover }">
            <v-btn
              v-bind="attrs"
              @click.native.stop="toClick(on, $event, newPassword)"
              class="pa-0 d-inline-block"
              text
              x-small
              :style="{ 'color': hover ? 'red' : 'blue' }"
            >Copy</v-btn>
          </v-hover>
        </template>

        <span>Copied</span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    model: Object,
    input: Object,
  },
  data() {
    return {
      newPassword: "",
    };
  },
  methods: {
    clickReset() {
      if (!confirm("คุณต้องการรีเซ็ตรหัสผ่านใช่หรือไม่ ?")) {
        return;
      }

      let url = `${this.input.extend.url}/${this.model.applicationUserId}`;

      this.$http.post(url).then((res) => {
        this.newPassword = res;
        this.showAlertSuccess(
          "รีเซ็ตรหัสผ่านสำเร็จ กรุณาส่งรหัสผ่านนี้ให้ user"
        );
      });
    },
    toClick({ mouseenter, mouseleave }, e, text) {
      var copyToClipboard = (text) => {
        var dummy = document.createElement("textarea");
        dummy.addEventListener("focusin", (e) => e.stopPropagation()); // https://github.com/euvl/v-clipboard/issues/18    fix bug on v-diablog
        document.body.appendChild(dummy);
        dummy.value = text;
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);
      };

      //https://stackoverflow.com/a/71811049
      clearTimeout(this._timerId);
      mouseenter(e);
      copyToClipboard(text);
      this._timerId = setTimeout(() => mouseleave(e), 300);
    },
  },
};
</script>

<style>
</style>
export default [
    {
        "id": "Hospital AA",
        "text": "Hospital AA"
    },
    {
        "id": "Hospital BB",
        "text": "Hospital BB"
    },
    {
        "id": "Hospital CC",
        "text": "Hospital CC"
    }
]
<template>
  <v-row class="begin-end-block">
    <pre>{{ text }}</pre>
    <v-col>
      <render-input
        :input="fieldConfig.begin"
        v-model="value.begin"
        :max="value.end"
        :disaled="disabled"
      ></render-input>
    </v-col>
    <v-col>
      <render-input
        :input="fieldConfig.end"
        v-model="value.end"
        :disaled="disabled"
        :min="value.begin"
      ></render-input>
    </v-col>
  </v-row>
</template>

<script>
import { InputType } from "../../js/constants";

export default {
  name: "begin-end-block",
  props: {
    value: Object,
    text: String,
    required: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: InputType.begin_end_date,
      validator: function(value) {
        // The value must match one of these strings
        return (
          [InputType.begin_end_date, InputType.begin_end_period].indexOf(
            value
          ) !== -1
        );
      },
    },
  },
  computed: {
    getInputType() {
      return this.type === InputType.begin_end_date
        ? InputType.datePicker
        : InputType.monthPicker;
    },
    fieldConfig() {
      return {
        begin: {
          name: "begin",
          text: "ตั้งแต่วันที่",
          type: this.getInputType,
          rule: {
            required: this.required,
          },
        },
        end: {
          name: "end",
          text: "จนถึงวันที่",
          type: this.getInputType,
          rule: {
            required: this.required,
          },
        },
      };
    },
  },
};
</script>

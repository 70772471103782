<template>
  <div
    v-if="showCondition"
    :key="forceRerenderRequired"
  >
    <template v-if="extendInputList.includes(type)">
      <RenderInputExtend
        :type="type"
        :model="model"
        :input="input"
        v-model="value"
        :disabled="disbledCondition"
      ></RenderInputExtend>
    </template>

    <template v-else-if="type === inputType.section">
      <v-subheader>
        <h2>{{ input.text }}</h2>
      </v-subheader>
    </template>

    <!-- address block -->
    <template v-else-if="type === inputType.address">
      <address-block v-model="value"></address-block>
    </template>
    <!-- end address block -->

    <!-- line connect block -->
    <template v-else-if="type === inputType.lineConnect">
      <line-connect></line-connect>
    </template>
    <!-- end line connect block -->

    <!-- label -->
    <template v-else-if="type === inputType.label">
      <v-list-item
        class="render-input-label"
        style="margin-bottom:20px"
      >
        <v-list-item-content style="margin-top: -29px">
          <v-list-item-title style="font-size:0.9em; color: #000;">{{ input.text }}</v-list-item-title>
          <v-list-item-subtitle
            style="padding-top: 6px; padding-left:4px"
            :class="{ 'text-right': isNumberType }"
          >
            <span v-if="isNumberType">{{ getLableText() | currency(2) }}</span>
            <span v-else>
              {{ getLableText() }}
            </span>
            <span style="visibility:hidden">.<span style="display:none">for workaround </span></span>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>
    <!-- end label -->

    <template v-else-if="type === inputType.button">
      <v-btn
        :text="input.buttonConfig.isText"
        :class="input.buttonConfig.class"
        @click="onButtonClick"
      >
        <v-icon
          v-if="input.buttonConfig.icon"
          left
        >{{
          input.buttonConfig.icon
        }}</v-icon>
        {{ input.text }}
      </v-btn>
    </template>

    <!-- begin end date-period -->

    <template v-else-if="
        [inputType.begin_end_date, inputType.begin_end_period].includes(type)
      ">
      <begin-end-block
        v-model="value"
        :type="input.type"
        :text="input.text"
        :disabled="disbledCondition"
        :required="rule.required"
      ></begin-end-block>
    </template>
    <!-- end begin end date-period -->

    <template v-else>
      <ValidationProvider
        :rules="rule"
        :name="input.text"
        v-slot="{ valid, errors }"
        :immediate="immediate"
        :vid="getVid"
      >
        <!-- require vid some condition like comfirmed -->

        <template v-if="type === inputType.number">
          <VueNumberFormat
            v-model="innerValue"
            :input="input"
            :errorMessage="errorMessage(valid, errors)"
            :disabled="disbledCondition"
            :decimal="input.decimal"
          ></VueNumberFormat>
        </template>

        <template v-else-if="type === inputType.text">
          <v-text-field
            v-model="innerValue"
            :label="input.text"
            :placeholder="OtherConfig.defaultPlaceHolder"
            :prepend-icon="input.icon"
            :suffix="input.suffixText"
            :error-messages="errorMessage(valid, errors)"
            :disabled="disbledCondition"
            @keydown="$emit('keydown', $event)"
            @keyup="onKeyUp"
            outlined
            background-color="white"
            dense
            persistent-placeholder
          ></v-text-field>
        </template>

        <template v-else-if="type === inputType.password">
          <v-text-field
            v-model="innerValue"
            :append-icon="isPasswordMode ? 'mdi-eye' : 'mdi-eye-off'"
            :type="isPasswordMode ? 'text' : 'password'"
            :label="input.text"
            :placeholder="OtherConfig.defaultPlaceHolder"
            counter
            :prepend-icon="input.icon"
            @click:append="isPasswordMode = !isPasswordMode"
            :error-messages="errorMessage(valid, errors)"
            :disabled="disbledCondition"
            @keydown="$emit('keydown', $event)"
            @keyup="$emit('keyup', $event)"
            outlined
            background-color="white"
            dense
            persistent-placeholder
          ></v-text-field>
        </template>

        <template v-else-if="
            [inputType.dropdown, inputType.dropdownRelate].includes(type)
          ">
          <v-autocomplete
            v-model="innerValue"
            :label="input.text"
            :placeholder="OtherConfig.defaultPlaceHolder"
            :items="items"
            :item-value="itemValue"
            :item-text="itemText"
            :error-messages="errorMessage(valid, errors)"
            return-object
            clearable
            :disabled="disbledCondition"
            @change="$emit('change', $event)"
            @keyup="onKeyUp"
            outlined
            background-color="white"
            dense
            persistent-placeholder
          >
            <template
              v-slot:append-outer
              v-if="
                input.name === 'namePrefix' &&
                  innerValue &&
                  innerValue.id === otherId
              "
            >
              <render-input
                style="margin-top: -7px; width: 180px"
                :input="fieldConfig.namePrefixRemark"
                v-model="model.namePrefixRemark"
              ></render-input>
            </template>
          </v-autocomplete>
        </template>

        <template v-else-if="type === inputType.dropdownMultiple">
          <v-autocomplete
            v-model="innerValue"
            :label="input.text"
            :items="items"
            :item-value="itemValue"
            :item-text="itemText"
            class="d-inline-block ml-5"
            return-object
            multiple
            chips
            deletable-chips
            clearable
            hide-selected
            :search-input.sync="search"
            @change="search = ''"
            dense
          ></v-autocomplete>
        </template>

        <template v-else-if="type === inputType.textArea">
          <!-- <v-textarea
            v-model="innerValue"
            :label="input.text"
            :placeholder="OtherConfig.defaultPlaceHolder"
            :error-messages="errorMessage(valid, errors)"
            :disabled="disbledCondition"
            @keydown="$emit('keydown', $event)"
            @keyup="$emit('keyup', $event)"
            outlined
            background-color="white"
            dense
          ></v-textarea> -->
          <v-textarea
            v-model="innerValue"
            :label="input.text"
            :placeholder="OtherConfig.defaultPlaceHolder"
            :error-messages="errorMessage(valid, errors)"
            @keydown="$emit('keydown', $event)"
            @keyup="$emit('keyup', $event)"
            outlined
            background-color="white"
            :readonly="disbledCondition"
            dense
            persistent-placeholder
          ></v-textarea>
        </template>

        <template v-else-if="type === inputType.checkBox">
          <v-checkbox
            v-model="innerValue"
            :indeterminate="indeterminate"
            :label="input.text"
            :error-messages="errorMessage(valid, errors)"
            :disabled="disbledCondition"
            @change="$emit('change', $event)"
            dense
            class="d-inline-block"
          ></v-checkbox>
        </template>

        <template v-else-if="type === inputType.radio">
          <v-radio-group
            v-model="innerValue"
            row
            :label="input.text"
          >
            <v-radio
              v-for="item in items"
              :key="item.id"
              :label="item.text"
              :value="item.id"
              :disabled="disbledCondition"
            ></v-radio>
          </v-radio-group>
        </template>

        <template v-else-if="
            [inputType.datePicker, inputType.monthPicker].includes(type)
          ">
          <custom-date-picker
            v-model="innerValue"
            :text="input.text"
            :disabled="disbledCondition"
            :min="min"
            :max="max"
            :type="type"
            :error-messages="errorMessage(valid, errors)"
          ></custom-date-picker>
        </template>

        <template v-else-if="[inputType.timePicker].includes(type)">
          <custom-time-picker
            v-model="innerValue"
            :text="input.text"
            :disabled="disbledCondition"
            :error-messages="errorMessage(valid, errors)"
            :config="input.config"
          ></custom-time-picker>
        </template>
      </ValidationProvider>
    </template>
  </div>
</template>

<script>
import {
  InputType,
  MasterMode,
  DATE_PICKER_TYPE,
  NamePrefix,
  OtherConfig,
} from "./../../js/constants";

import AddressBlock from "./address-block";
import BeginEndBlock from "./begin-end-block";
import LineConnect from "./../global/line-connect";
import { createValidateRule } from "./../../js/custom";
import DropdownRelateMixins from "./../../_mixins/dropdown-relate-mixins";
import CustomTimePicker from "./../../components/custom/custom-timepicker";
import VueNumberFormat from "./../../components/custom/VueNumberFormat";

import RenderInputExtend, {
  extendInputList,
} from "./../../_specific/render-input-extend";

export default {
  name: "render-input",
  mixins: [DropdownRelateMixins],
  props: {
    input: Object,
    indeterminate: Boolean,
    value: [String, Object, Array, Boolean, Number],
    mode: String,
    from: String,
    model: Object,
    disabled: Boolean,
    autoFetchItems: {
      type: Boolean,
      default: true,
    },
    required: Boolean,
    min: String, //use for date/month picker
    max: String, //use for date/month picker
  },
  components: {
    "address-block": AddressBlock,
    "begin-end-block": BeginEndBlock,
    "custom-time-picker": CustomTimePicker,
    "line-connect": LineConnect,
    RenderInputExtend,
    VueNumberFormat,
  },
  data() {
    return {
      isPasswordMode: false,
      search: null,
      immediate: false,
      inputType: InputType,
      items: [],
      rule: {},
      forceRecomputeCounter: 0,
      forceRerenderRequired: 0,
      otherId: NamePrefix.otherId,
      extendInputList,
      OtherConfig,
    };
  },
  watch: {
    required: {
      handler(newVal) {
        if (!_.isNil(newVal)) {
          this.$set(this.rule, "required", newVal);
          this.forceRerenderRequired++;
        }
      },
      immediate: true,
    },
    model: {
      handler(newVal) {
        //trigger computed
        if (process.env.NODE_ENV === "development") {
          // log("model changes");
        }
        this.thisShouldTriggerRecompute();
      },
      deep: true,
    },
    input: {
      handler(newVal) {
        this.createValidate();
        if (this.type === InputType.dropdownRelate) {
          this.fetchRelateItems(this.parentVal);
        }

        if (this.autoFetchItems) {
          this.fetchItems();
        }
      },
      immediate: true,
      deep: true,
    },
    items: {
      handler(newVal) {
        this.$emit("items-change", newVal);
      },
    },
    value: {
      handler(newVal) {
        //add item is not exist in items dropdown
        if (newVal && this.input.type === this.inputType.dropdown) {
          let existing = this.items.find((x) => this.value.id);
          if (!existing) {
            this.items.push(_.cloneDeep(this.value));
          }
        }
      },
    },
  },
  created() {},
  mounted() {},
  methods: {
    errorMessage(valid, errors) {
      if (!valid) {
        if (errors.length > 0) {
          return errors[0];
        } else {
          return "This field is required";
        }
      }
    },
    setItems(items) {
      this.items = items;
    },
    fetchItems() {
      if ([InputType.dropdown, InputType.radio].includes(this.type)) {
        const data = this.input.data;

        if (data) {
          const promises = [];
          // pass promise contains fix data
          if (data.promise) {
            promises.push(data.promise);
          }

          if (data.func) {
            promises.push(data.func(this));
          }

          //pass url for fetch backend
          if (data.url) {
            const fetchUrl = this.$http.get(data.url);
            promises.push(fetchUrl);
          }

          this.items.length = [];
          Promise.all(promises).then((reses) => {
            reses.forEach((res) => {
              this.items.push(...res.items); //merge array
            });
          });
        }
      }
    },
    createValidate() {
      let condition = createValidateRule(this.input);
      this.rule = {};
      Object.assign(this.rule, condition);
    },
    thisShouldTriggerRecompute() {
      this.forceRecomputeCounter++;
    },
    onKeyUp(event) {
      if (this.input.event && this.input.event.keyUp) {
        this.input.event.keyUp(event, this.innerValue);
      } else {
        this.$emit("keyup", event);
      }
    },
    onButtonClick() {
      this.input.buttonConfig.click(this);
    },
    getLableText() {
      if (this.input.labelPath) {
        return _.get(this.innerValue, this.input.labelPath);
      }
      return this.innerValue;
    },
  },
  computed: {
    getVid() {
      if (this.input?.name) {
        return this.input.name.toLowerCase().includes("password")
          ? this.input.name
          : this.input.name + "_" + this._uid;
      }
      return this._uid;
    },
    fieldConfig() {
      return {
        namePrefixRemark: {
          name: "namePrefix",
          text: "ระบุ",
          type: InputType.text,
          rule: {
            required: true,
          },
        },
      };
    },
    itemValue() {
      if (this.input.prop) {
        return this.input.prop.id;
      }
      return "id";
    },
    itemText() {
      if (this.input.prop) {
        return this.input.prop.text;
      }
      return "text";
    },
    innerValue: {
      get() {
        let val = null;
        if (!_.isNil(this.value)) {
          val = this.value;
        } else {
          val = null;
        }

        return val;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    type() {
      return this.input.type;
    },
    text() {
      return this.input.text;
    },
    isNumberType() {
      if (this.input.rule && this.input.rule.number) {
        return true;
      }

      return false;
    },
    disbledCondition() {
      if (this.disabled) {
        return true;
      }

      if (this.input.disabled) {
        return true;
      }

      if (this.input.parent && !this.parentVal) {
        return true;
      }

      if (this.mode === MasterMode.edit && this.input.disabledWhenEdit) {
        return true;
      }

      if (this.forceRecomputeCounter) {
        //trigger computed when model changes
      }

      //lates priority
      if (this.input.disabledIf) {
        let result = this.input.disabledIf(this);
        //check null or undefined
        if (!_.isNil(result)) {
          return result;
        }
      }
      return false;
    },
    showCondition() {
      if (process.env.NODE_ENV === "development") {
        // log("showCondition");
      }

      if (this.mode === MasterMode.edit && this.input.hideWhenEdit) {
        return false;
      }

      if (this.forceRecomputeCounter) {
        //trigger computed when model changes
      }

      //lates priority
      if (this.input.showIf) {
        let result = this.input.showIf(this);
        //check null or undefined
        if (!_.isNil(result)) {
          return result;
        }
      }

      return true;
    },
  },
};
</script>

<style>
.v-input.text-right input {
  text-align: right;
}
</style>

export default [
    {
        "id": "Customer AA",
        "text": "Customer AA"
    },
    {
        "id": "Customer BB",
        "text": "Customer BB"
    },
    {
        "id": "Customer CC",
        "text": "Customer CC"
    }
]
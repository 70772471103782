<template>
  <div>
    <UploadFileButton
      icon="mdi-upload"
      text="Upload"
      accept="image"
      :multiple="true"
      @changedFile="uploadImages"
      @upload-success="tik()"
    ></UploadFileButton>

    <SimpleGallery
      :key="forceRerenderRequired"
      class="mt-1 mb-10"
      :items="imageList"
      @click-delete="clickDeleteImage"
    ></SimpleGallery>
  </div>
</template>

<script>
import UploadFileButton from "./../components/custom/uploadfile-button.vue";
import UploadFileMixins from "./../_mixins/upload-file-mixins";
import SimpleGallery from "./SimpleGallery.vue";

export default {
  components: {
    SimpleGallery,
    UploadFileButton,
  },
  mixins: [UploadFileMixins],

  props: {
    model: {
      type: Object,
    },
  },
  data() {
    return {
      imageList: [],
      forceRerenderRequired: 0,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    handleUploadSuccess() {
      if (this.uploadFileModel.completed == this.uploadFileModel.total) {
        this.getData();
      }
    },
    getData() {
      let url = "";
      if (this.$route.path.includes("profile")) {
        url = `/member/profile/MemberImages`;
      } else if (this.$route.path.includes("member")) {
        let memberId = this.$route.params.id;
        url = `/member/${memberId}/MemberImages`;
      }

      this.$http.get(url).then((res) => {
        this.imageList = res.items;
      });
    },
    uploadImages(files) {
      let items = this.handleFileChanges(files);

      let uploadUrl = "/member/uploadFile";
      let appendForm = Object.assign({}, { memberId: this.model.id });

      this.uploadFiles(items, uploadUrl, appendForm);
    },
    clickDeleteImage(item) {
      let url = `/member/MemberImages/?fileId=${item.id}`;

      this.$http.delete(url).then((res) => {
        let removedItems = _.remove(this.imageList, (image) => {
          return image.id === item.id;
        });
        // this.getData();

        this.forceRerenderRequired++;
      });
    },
  },
};
</script>

<style>
</style>
import { getUID, urlToPayload, filterString } from '../js/custom';
import db from '../_helpers/fake-db';

const colletionName = 'sub-district'

function create(url, opts) {
    let items = db.getCollection(colletionName);
    let request = opts.body;
    request.id = getUID();

    items.push(request);

    db.updateCollection(colletionName, items)

    return request;
}

function update(url, opts) {
    let items = db.getCollection(colletionName);
    let request = opts.body;
    let index = items.findIndex(x => x.id === request.id)
    items.splice(index, 1, request)

    db.updateCollection(colletionName, items)
    return request;
}

function get(url, opts) {
    const id = url.split('/').pop();
    let items = db.getCollection(colletionName);
    let res = items.find(x => x.id === id)
    return res;
}

function search(url, opts) {
    let items = db.getCollection(colletionName);
    let criteria = urlToPayload(url)

    for (var key in criteria) {
        if (criteria.hasOwnProperty(key)) {
            if (criteria[key]) {
                items = filterString(key, criteria, items)
            }
        }
    }

    return {
        items
    }
}

function remove(url, opts) {
    let id = url.split('/').pop()
    let items = db.getCollection(colletionName);
    let index = items.findIndex(x => x.id === id);
    items.splice(index, 1)
    db.updateCollection(colletionName, items);
}


export default {
    search,
    get,
    create,
    update,
    remove
};



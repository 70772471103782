
export default {
    namespaced: true,
    state: {
        drawer: null,
        color: 'success',
        image: 'https://demos.creative-tim.com/vue-material-dashboard/img/sidebar-2.32103624.jpg'
    },
    mutations: {
        setDrawer(state, payload) {
            state.drawer = payload;
        },
        setImage(state, payload) {
            state.image = payload;
        },
        setColor(state, payload) {
            state.image = payload;
        },
        toggleDrawer(state, payload) {
            state.drawer = !state.drawer
        },
    }
}

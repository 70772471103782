import Vue from 'vue'

export default Vue.directive('number-only', {
  update(el, binding, n, o) {
    let input = getInput(el)
    triggerInput(input, binding);

    var event = new Event('input', {
      'bubbles': true,
    });
    // input.dispatchEvent(event);
  },
  bind: function (el, binding, vnode) {
    // log($(el));

    // log($(el)[0].children[0].children[0].children[0].children[1].value);
    let input = getInput(el)

    input.style.textAlign = 'right';

    input.addEventListener("input", event => {
      triggerInput(input, binding);
      // el.dispatchEvent(new Event('input', { bubbles: true }))
    });
  }
})

function getInput(el) {
  let input = $(el)[0].children[0].children[0].children[0].children[1];
  return input
}



var triggerInput = function (input, binding) {
  // log(el);
  // let input = $(el)[0].children[0].children[0].children[0].children[1];
  input.value = input.value.replace(/\D/g, '');
  // el.value = el.value.replace(/\D/g, '');


}



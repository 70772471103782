<template>
  <div class="custom-datepicker">
    <v-menu
      ref="menu"
      v-model="menu"
      offset-x
      max-width="290px"
      min-width="290px"
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          :label="getText"
          :placeholder="OtherConfig.defaultPlaceHolder"
          v-model="inputValue"
          :loading="loading"
          :disabled="disabled"
          :clearable="clearable"
          @click:clear="clickClear"
          @click="$refs.icon.$el.click()"
          outlined
          :error-messages="errorMessages"
          dense
          persistent-placeholder
        >
          <template v-slot:append>
            <v-icon
              ref="icon"
              v-on="on"
              :disabled="disabled"
            >mdi-calendar
            </v-icon>
          </template>
        </v-text-field>
      </template>

      <v-date-picker
        :key="trigger"
        v-model="pickerValue"
        :type="getType"
        no-title
        scrollable
        locale="th"
        @input="menu = false"
        :min="minDate"
        :max="maxDate"
      ></v-date-picker>
    </v-menu>
  </div>
</template>

<script>
import {
  DateFormat,
  DATE_PICKER_TYPE,
  OtherConfig,
} from "./../../js/constants";
import * as dateHelper from "./../../js/date-helper";
import moment from "moment";

export default {
  name: "custom-date-picker",
  props: {
    text: String,
    value: String,
    min: String,
    max: String,
    type: {
      type: String,
      default: DATE_PICKER_TYPE.DATE,
      validator: function (value) {
        // The value must match one of these strings
        return (
          [DATE_PICKER_TYPE.DATE, DATE_PICKER_TYPE.MONTH].indexOf(value) !== -1
        );
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    thaiYear: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    errorMessages: {
      type: String,
    },
  },
  data() {
    return {
      menu: false,
      trigger: false,
      OtherConfig,
    };
  },
  watch: {
    pickerValue: {
      handler(newVal) {
        if (!newVal) {
          //trigger datepicker re-render when value invalid or null;
          this.trigger = !this.trigger;
        }
      },
    },
  },
  created() {},
  methods: {
    toBeautyFormat(val) {
      // params is christ year
      let result = dateHelper.toBeautyFormat(val, this.thaiYear);
      return result;
    },
    toStandardFormat(val) {
      // params is buddhist year
      let result = dateHelper.toStandardFormat(val, this.thaiYear);

      return result;
    },
    clickClear() {
      this.$emit("input", null);
    },
  },
  computed: {
    getText() {
      return this.text || "Select date";
    },
    getType() {
      return this.type || DATE_PICKER_TYPE.DATE;
    },
    inputValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    pickerValue: {
      get() {
        let result = dateHelper.validateThaiFormat({
          type: this.getType,
          value: this.value,
        });

        let value = null;

        if (result.valid) {
          value = result.christYear;
        }

        return value;
      },
      set(val) {
        let buf = this.toBeautyFormat(val);
        this.$emit("input", buf);
      },
    },
    minDate() {
      let buf = this.toStandardFormat(this.min);
      return buf;
    },
    maxDate() {
      let buf = this.toStandardFormat(this.max);
      return buf;
    },
    getBeautyFormat() {
      let format =
        this.getType === DATE_PICKER_TYPE.DATE
          ? DateFormat.beautyDateFormat
          : DateFormat.beautyPeriodFormat;

      return format;
    },
    getDpFormat() {
      let format =
        this.getType === DATE_PICKER_TYPE.DATE
          ? DateFormat.dateStandardFormat
          : DateFormat.PeriodStandardFormat;

      return format;
    },
  },
};
</script>


<style>
.custom-datepicker .v-input__append-inner div {
  display: none;
}
</style>
export default [
    {
        "id": "inspector AA",
        "text": "inspector AA"
    },
    {
        "id": "inspector BB",
        "text": "inspector BB"
    },
    {
        "id": "inspector CC",
        "text": "inspector CC"
    }
]
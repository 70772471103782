
export default {
    namespaced: true,
    state: {
        loading: null,
    },
    mutations: {
        showLoading(state) {
            state.loading = true

        },
        hideLoading(state) {
            state.loading = false
        },
    },
    actions: {
        showLoading({ commit }) {
            commit('showLoading');
        },
        hideLoading({ commit }) {
            commit('hideLoading');
        }
    }
}

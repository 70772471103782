<template>
  <div>
    <Photoswipe :options="{ shareEl: false }">
      <div
        v-for="(item, index) in items"
        :key="`practical-bg-${index}`"
        v-pswp="item"
        :style="getImageItemStyle(item.msrc)"
        style="display: inline-block;margin-left:4px"
        class="box"
      >
        <div class="bet_time">
          <custom-tooltip
            v-slot="{ on }"
            title="Download"
          >
            <i
              class="mdi mdi-download thetik-icon"
              @click="download(item)"
              v-on="on"
            ></i>
          </custom-tooltip>
          <custom-tooltip
            v-slot="{ on }"
            title="Delete"
          >
            <i
              class="mdi mdi-delete thetik-icon"
              @click="remove(item)"
              v-on="on"
            ></i>
          </custom-tooltip>
        </div>
      </div>
    </Photoswipe>
  </div>
</template>

<style>
.box {
  position: relative;
}

.bet_time {
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
  background: #e0e0e0;
  font-size: 20px;
}

.thetik-icon {
  border: 3px solid black;
  margin: 0px;
  padding-left: 2px;
  padding-right: 2px;
}
</style>

<script>
export default {
  props: {
    items: Array,
  },
  data() {
    return {
      // items: [
      //   {
      //     id: "d7f3413d-a3f6-41d2-63ae-08da2c884dd9",
      //     src:
      //       "https://pacsdata.blob.core.windows.net/user-upload-execution-production/25650503050835_aa1.png",
      //     msrc:
      //       "https://pacsdata.blob.core.windows.net/user-upload-execution-production/25650503050835_aa1.png",
      //     size: "1600x1600",
      //   },
      //   {
      //     id: "6aa6f6a2-bc5d-4583-63af-08da2c884dd9",
      //     src:
      //       "https://pacsdata.blob.core.windows.net/user-upload-execution-production/25650503050835_aa2.png",
      //     msrc:
      //       "https://pacsdata.blob.core.windows.net/user-upload-execution-production/25650503050835_aa2.png",
      //     size: "1600x1600",
      //   },
      // ],
      // items: [
      //   {
      //     src: "https://farm4.staticflickr.com/3894/15008518202_c265dfa55f_h.jpg",
      //     size: "1600x1600",
      //     msrc: "https://farm4.staticflickr.com/3894/15008518202_b016d7d289_m.jpg",
      //     title: "this is dummy caption",
      //   },
      //   {
      //     src: "https://www.isranews.org/images/thumbnails/images/2019/invest/9/pickdkdkdkdkdkdkdk27-fill-800x1098.jpg",
      //     size: "1600x1068",
      //     msrc: "https://www.isranews.org/images/thumbnails/images/2019/invest/9/pickdkdkdkdkdkdkdk27-fill-800x1098.jpg",
      //     title: "this is dummy caption",
      //   },
      //   {
      //     src: "https://www.isranews.org/images/thumbnails/images/2019/invest/9/pickdkdkdkdkdfffkdkddddddddk27-fill-800x1098.jpg",
      //     size: "1600x1068",
      //     msrc: "https://www.isranews.org/images/thumbnails/images/2019/invest/9/pickdkdkdkdkdfffkdkddddddddk27-fill-800x1098.jpg",
      //     title: "this is dummy caption",
      //   },
      //   {
      //     src: "https://farm4.staticflickr.com/3902/14985871946_24f47d4b53_h.jpg",
      //     size: "1600x1067",
      //     msrc: "https://farm4.staticflickr.com/3902/14985871946_86abb8c56f_m.jpg",
      //     title: "this is dummy caption",
      //   },
      //   {
      //     src: "https://farm4.staticflickr.com/3902/14985871946_24f47d4b53_h.jpg",
      //     size: "200x200",
      //     msrc: "https://farm4.staticflickr.com/3902/14985871946_86abb8c56f_m.jpg",
      //     title: "this is dummy caption",
      //   },
      // ],
    };
  },
  watch: {
    items: {
      handler(newVal) {},
      // immediate: true,
    },
  },
  methods: {
    remove(item) {
      this.$emit("click-delete", item);
    },
    download(item) {
      const link = document.createElement("a");
      link.href = item.src;
      link.download = item.fileName;
      link.click();
    },
    getImageItemStyle(src) {
      return {
        width: "160px",
        height: "160px",
        backgroundImage: `url(${src})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      };
    },
  },
};
</script>
<style lang="scss">
.hello-slide {
  width: 100%;
  max-width: 400px;
  color: #fff;
  margin: 120px auto 0;
  text-align: center;
  font-family: "Helvetica Neue", Arial, sans-serif;
}
.hello-slide a {
  color: #b5aef7 !important;
}

.pswipe-gallery .box {
  width: 160px !important;
  height: 160px !important;
}
</style>

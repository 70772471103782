import { getUID, urlToPayload, filterString } from './../js/custom';
import { StudyStatus } from './../js/constants';
import mock from './../_mock-data/dropdown';

import { fakeAbbr } from './../_helpers/fake-url';


function getBrands() {
    return {
        items: mock.brands
    }
}

function getSubBrands() {
    return {
        items: mock.subBrands
    }
}

function getCustomers() {
    return {
        items: mock.customers
    }
}

function getHospitals() {
    return {
        items: mock.hospitals
    }
}

function getEngineers() {
    return {
        items: mock.engineers
    }
}

function getInspectors() {
    return {
        items: mock.inspectors
    }
}


function filterByParentId(items, url) {
    let arr = url.split('=');
    let parentId = arr[1];

    if (parentId) {
        items = items.filter(x => x.parentId.toLowerCase() === parentId);
    }
    return items;
}


function endpoint(url, opts) {
    url = url.toLowerCase();
    let method = opts.method;

    if (url.includes(fakeAbbr.dropdown.brand.toLowerCase())) {
        let model = getBrands();
        model.items = filterByParentId(model.items, url)
        return model;
    }
    else if (url.includes(fakeAbbr.dropdown.subBrand.toLowerCase())) {
        let model = getSubBrands();
        model.items = filterByParentId(model.items, url)
        return model;
    }
    else if (url.includes(fakeAbbr.dropdown.customer.toLowerCase())) {
        let model = getCustomers();
        model.items = filterByParentId(model.items, url)
        return model;
    }
    else if (url.includes(fakeAbbr.dropdown.hospital.toLowerCase())) {
        let model = getHospitals();
        model.items = filterByParentId(model.items, url)
        return model;
    }
    else if (url.includes(fakeAbbr.dropdown.engineer.toLowerCase())) {
        let model = getEngineers();
        model.items = filterByParentId(model.items, url)
        return model;
    }
    else if (url.includes(fakeAbbr.dropdown.inspector.toLowerCase())) {
        let model = getInspectors();
        model.items = filterByParentId(model.items, url)
        return model;
    }

}

export default { endpoint };

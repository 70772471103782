<template>
  <div>
    <v-menu
      ref="menu"
      v-model="menu"
      offset-y
      max-width="290px"
      min-width="200px"
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          :label="getText"
          :placeholder="OtherConfig.defaultPlaceHolder"
          v-model="inputTimeValue"
          :loading="loading"
          :disabled="disabled"
          :clearable="clearable"
          @click:clear="clickClear"
          @click="$refs.icon.$el.click()"
          outlined
          background-color="white"
          :error-messages="errorMessages"
          suffix="น."
          dense
          persistent-placeholder
        >
          <template v-slot:append>
            <v-icon
              ref="icon"
              v-on="on"
              :disabled="disabled"
            >mdi-av-timer</v-icon>
          </template>
        </v-text-field>
      </template>

      <time-picker
        v-model="inputTimeValue"
        :config="config"
      ></time-picker>
    </v-menu>
  </div>
</template>

<script>
import TimePicker from "./time-picker";
import { OtherConfig } from "./../../js/constants";

export default {
  name: "custom-date-picker",
  components: {
    TimePicker,
  },
  props: {
    text: String,
    value: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    errorMessages: {
      type: String,
    },
    config: {
      type: Object,
      default: () => {
        return {
          interval: 10,
        };
      },
    },
  },
  data() {
    return {
      menu: false,
      trigger: false,
      OtherConfig,
    };
  },
  watch: {
    pickerValue: {
      handler(newVal) {
        if (!newVal) {
          //trigger datepicker re-render when value invalid or null;
          this.trigger = !this.trigger;
        }
      },
    },
  },
  created() {},
  methods: {
    clickClear() {
      this.$emit("input", null);
    },
  },
  computed: {
    getText() {
      return this.text || "Select time";
    },
    inputTimeValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>

<template>
  <div>
    <v-btn
      tile
      class="light-blue darken-1 white--text"
      :class="customClass"
      @click="chooseFiles"
      :disabled="disabled"
    >
      <v-icon :left="text ? true : false">{{ icon }}</v-icon>
      <span v-if="text">{{ text }}</span>
    </v-btn>

    <input
      :id="id"
      style="display: none;"
      type="file"
      @change="handleFile"
      :multiple="multiple"
      :accept="conditionAccept"
    />
  </div>
</template>

<script>
export default {
  props: {
    multiple: Boolean,
    customClass: String,
    icon: String,
    text: String,
    disabled: Boolean,
    accept: {
      type: String,
      default: "all",
    },
  },
  methods: {
    chooseFiles() {
      document.getElementById(this.id).click();
    },
    handleFile(e) {
      let files = e.target.files;
      this.$emit("changedFile", files);

      e.target.value = "";
    },
  },
  computed: {
    id() {
      return "fileInput" + this._uid;
    },
    conditionAccept() {
      switch (this.accept) {
        case "all":
          return "";
        case "image":
          return "image/*";
        case "excel":
          return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel";
      }
    },
  },
};
</script>

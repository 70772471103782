<template>
  <v-btn @click="$emit('clicked')" color="primary" class="pl-1" dark outlined
    ><v-icon v-if="icon">{{ icon }}</v-icon
    >{{ text }}</v-btn
  >
</template>

<script>
export default {
  name: "button-add",
  props: {
    text: String,
    icon: String,
  },
};
</script>
import { render, staticRenderFns } from "./render-input-extend.vue?vue&type=template&id=0f87217a&"
import script from "./render-input-extend.vue?vue&type=script&lang=js&"
export * from "./render-input-extend.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
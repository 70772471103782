import moment from 'moment';
import { DateFormat, DATE_PICKER_TYPE } from './../js/constants';

export function getCurrentPeriod({ isThaiyear = true } = {}) {
    let date = moment();
    let currentPeriod = date
        .startOf("month")
        .format(DateFormat.PeriodStandardFormat);

    currentPeriod = toThaiFormat(currentPeriod, isThaiyear)
    return currentPeriod;
}


export function getBeginDateCurrentMonth({ isThaiyear = true } = {}) {
    let date = moment().startOf("month");
    return momentToThaiFormat({ moment: date, isThaiyear })
}

export function getEndDateCurrentMonth({ isThaiyear = true } = {}) {
    let date = moment().endOf("month");
    return momentToThaiFormat({ moment: date, isThaiyear })
}

export function momentToThaiFormat({ moment, isThaiyear = true } = {}) {
    let dateStr = moment
        .format(DateFormat.dateStandardFormat);

    dateStr = toThaiFormat(dateStr, isThaiyear)
    return dateStr;
}



export function toBeautyFormat(val, { isThaiyear = true } = {}) {
    // params is christ year format as yyyy/mm/dd
    //return buddhist format as dd/mm/yyyy
    return toThaiFormat(val, isThaiyear);
}


export function toStandardFormat(val, { isThaiyear = true } = {}) {
    // params is buddhist year as dd/mm/yyyy
    //return yyyy/mm/dd in chirst year
    return toChistFormat(val, isThaiyear)
}

function toThaiFormat(christDate, isThaiyear = true) {
    let diffYear = (isThaiyear) ? DateFormat.diffThaiYear : 0;
    let str = "";
    if (christDate) {
        let arr = christDate.split('-');
        let year = Number(arr[0]) + diffYear;

        if (arr.length === 3) {
            str = `${arr[2].padStart(2, "0")}/`
        }
        str += `${arr[1].padStart(2, "0")}/${year.toString()}`
    }

    return str;
}


function toChistFormat(thaiDate, isThaiyear = true) {
    let diffYear = (isThaiyear) ? DateFormat.diffThaiYear : 0;
    let str = "";
    if (thaiDate) {
        let arr = thaiDate.split('/');

        if (arr.length === 3) {
            str = `${arr[0].padStart(2, "0")}`
            arr.splice(0, 1)
        }
        let year = Number(arr[1]) - diffYear;
        str = `${year.toString()}-${arr[0].padStart(2, "0")}-${str}`
    }
    return str;
}


export function validateThaiFormat({ type, value }) {
    if (![DATE_PICKER_TYPE.DATE, DATE_PICKER_TYPE.MONTH].includes(type)) {
        throw new Error("Invalid date picker type.");
    }

    const result = {
        input: value,
        valid: false,
        thaiYear: null,
        christYear: null
    }


    let regex = null;
    if (type === DATE_PICKER_TYPE.DATE) {
        regex = /^[0-9]{2}[\/][0-9]{2}[\/][0-9]{4}$/g;
    } else {
        regex = /^[0-9]{2}[\/][0-9]{4}$/g;
    }

    if (value && regex.test(value)) {
        try {
            let standardYear = toStandardFormat(value);
            let valid = moment(standardYear).isValid();
            if (valid) {
                let thaiYear = toBeautyFormat(standardYear);
                result.valid = true;
                result.christYear = standardYear;
                result.thaiYear = thaiYear;
            }
        } catch (er) {

        }
    }


    return result;

}
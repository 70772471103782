<template>
  <div v-if="false">
    <v-btn
      dark
      @click="snackbar = true"
    >Show Model</v-btn>
    <v-snackbar
      v-model="snackbar"
      :timeout="-1"
    >
      <v-row no-gutters>
        <v-col cols="10">
          <div ref="divText">{{ text }}</div>
        </v-col>
        <v-col
          align-self="center"
          class="text-center"
        >
          <div>
            <v-btn
              color="pink"
              text
              class="pa-0"
              @click="copy"
            >{{
              copyTextButton
            }}</v-btn>
          </div>
          <div class="mt-2">
            <v-btn
              color="pink"
              text
              class="pa-0"
              @click="snackbar = false"
            >Close</v-btn>
          </div>
        </v-col>
      </v-row>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: "show-model",
  props: ["text"],
  data() {
    return {
      snackbar: false,
      copyTextButton: null,
    };
  },
  watch: {
    snackbar: {
      handler(newVal, oldVal) {
        if (newVal) {
          this.initCopyText();
        }
      },
    },
  },
  created() {
    this.initCopyText();
  },
  methods: {
    initCopyText() {
      this.copyTextButton = "Copy";
    },
    copy() {
      this.selectText(this.$refs.divText);
      document.execCommand("copy");
      this.copyTextButton = "Copied";
    },
    selectText(element) {
      var range;
      if (document.selection) {
        // IE
        range = document.body.createTextRange();
        range.moveToElementText(element);
        range.select();
      } else if (window.getSelection) {
        range = document.createRange();
        range.selectNode(element);
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(range);
      }
    },
  },
};
</script>

import brands from './brand';;
import customers from './customer';;
import engineers from './engineer';;
import hospitals from './hospital';;
import inspectors from './inspector';;
import subBrands from './sub-brand';;

export default {
    brands,
    customers,
    engineers,
    hospitals,
    inspectors,
    subBrands,
}




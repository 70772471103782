export class Usr {
  static Support = "4103722421120";
}

export const LimitMemberPerTeam = 10;


export const OtherConfig = {
  defaultPlaceHolder: ".",
};


export const Server = {
  Local: "local",
  Development: "development",
  Test: "test",
  Staging: "staging",
  Production: "production",
};

export class DateFormat {
  static diffThaiYear = 543;
  static beautyDateFormat = "DD/MM/YYYY";
  static dateStandardFormat = "YYYY-MM-DD";

  static beautyPeriodFormat = "MM/YYYY";
  static PeriodStandardFormat = "YYYY-MM";
}

export const DATE_PICKER_TYPE = {
  DATE: "date",
  MONTH: "month",
};

export const AgreementType = {
  Normal: "Normal",
  Demo: "Demo",
};

export const ServiceType = {
  PM: "PM",
  MA: "MA",
  Demo: "Demo",
  Extenal: "Extenal",
};

export const CreateTeamType = {
  NEW: "NEW",
  EXISTING: "EXISTING",
};

export const UserRole = {
  ADMIN: "ADMIN,a01b884d-f720-4fb5-a0d8-f924032e3151",
  MEMBER: "MEMBER,8e764005-1b5e-4d63-a8df-d70fc73c8c24",
  MENTOR: "MENTOR,683bec9b-b98e-4570-a1cc-fb0d1b244789",
  SUPER_USER_UNIVERSITY: "SUPER_USER_UNIVERSITY,5c8f3cf9-1eb3-4e7c-bd8c-063db5f4d934",
};

export const PriorityRoles = [UserRole.MANAGER, UserRole.ADMIN];

export const StatusSaleContract = {
  รอการติดตั้ง: "รอการติดตั้ง",
  ติดตั้งเสร็จแล้ว: "ติดตั้งเสร็จแล้ว",
  รอตรวจรับ: "รอตรวจรับ",
  อยู่ในประกัน: "อยู่ในประกัน",
  หมดอายุประกัน: "หมดอายุประกัน",
  InActive: "InActive",
};

export const StatusDemoContract = {
  รอการติดตั้ง: "รอการติดตั้ง",
  Active: "Active",
  InActive: "InActive",
};

export const TaskCode = {
  Installation: "installation",
  Delivery: "deliver",
  OnCall: "onCall",
  PM: "pm",
  MA: "ma",
  ReturnMachine: "return-machine",
};

export const StatusTaskPMOnCall = {
  OPEN: "OPEN",
  รอตรวจรับ: "รอตรวจรับ",
  CLOSED: "ปิดงาน",
};

export const StatusTaskOther = {
  OPEN: "OPEN",
  CLOSED: "ปิดงาน",
};

export const StatusFile = {
  FAIL: "fail",
  PENDING: "pending",
  UPLOADED: "uploaded",
};

export const InputType = {
  dropdown: "dropdown",
  dropdownMultiple: "dropdown-multiple",
  text: "text",
  number: "number",
  checkBox: "checkbox",
  radio: "radio",
  password: "password",
  textArea: "text-area",
  datePicker: DATE_PICKER_TYPE.DATE,
  monthPicker: DATE_PICKER_TYPE.MONTH,
  timePicker: "time-picker",
  section: "section",
  offset: "offset",
  label: "label",
  button: "button",
  resetPassword: 'reset-password',
  rowSetting: "row-setting",
  begin_end_date: "begin_end_date",
  begin_end_period: "begin_end_period",
  dropdownRelate: "dropdown-relate",
  address: "address-block",
  lineConnect: "line-connect",
  memberGallery: "member-gallery",
  historyGallery: "history-gallery",
};

export const KeywordUIDialog = {
  job: "job",
  installation: "installation",
  returnMachine: "returnMachine",
  createOncall: "createOncall",
  editActionDate: "editActionDate",
  serviceDemo: "serviceDemo",
  servicePM: "servicePM",
  serviceMA: "serviceMA",
  serviceExternal: "serviceExternal",
};

export const WrapType = {
  alertSuccess: "alert-success",
};

export const MasterMode = {
  add: "add",
  edit: "edit",
};

export const DialogSize = {
  small: "small",
  medium: "medium",
  fullScreen: "full-screen",
};

export const Sorting = {
  ascending: "ascending",
  descending: "descending",
};

export const ProductType = {
  Machine: "machine",
  Modality: "modality",
};

export const NamePrefix = {
  otherId: "a8c35b9e-b3dd-4ba5-8871-be08c492d323",
};

function getCollection(name) {
    //default collection
    if (!localStorage.hasOwnProperty(name) || !localStorage.getItem(name)) {
        localStorage.setItem(name, '[]')
    }

    return JSON.parse(localStorage.getItem(name))
}

function updateCollection(name, newArr) {
    localStorage.setItem(name, JSON.stringify(newArr))
}


export default {
    getCollection,
    updateCollection
}